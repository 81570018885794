import { gql } from 'apollo-angular';

// #region Query

export const CurrentUserQuery = gql`
  query CurrentUser {
    currentUser {
      id
      firstName
      lastName
      phoneNumber
      languageId
      currentClub {
        id
        name
        logoImage {
          id
          thumbnail
        }
      }
      profilePhoto {
        id
        thumbnail
      }
    }
  }
`;
// #endregion
