import { gql } from 'apollo-angular';

export const selectOneFields = gql`
  fragment SelectOneFieldsUser on User {
    id
    firstName
    lastName
    language {
      id
      isoCode2
    }
    profilePhoto {
      id
      thumbnail
    }
    phoneNumber
  }
`;

export const selectAllFields = gql`
  fragment SelectAllFieldsUser on User {
    id
    firstName
    lastName
  }
`;

export const selectDeleteRestoreFields = gql`
  fragment DeleteRestoreFieldsUser on User {
    id
    deleted
  }
`;
