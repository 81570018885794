import { Injectable } from '@angular/core';
import { User } from '@gqlSchema';
import { map, Observable } from 'rxjs';
import { CurrentUserQuery } from '../../../core/graphql/core.fragments';
import { BaseService } from '../../../core/services/base.service';
import {
  selectAllFields,
  selectDeleteRestoreFields,
  selectOneFields,
} from '../graphql/user.fragments';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService<User> {
  protected override selectOneFields = selectOneFields;
  protected override selectAllFields = selectAllFields;
  protected override deleteRestoreFields = selectDeleteRestoreFields;

  constructor() {
    super();
    this.initGQL({
      names: { oneName: 'user' },
      args: { updateParams: '$item: UserUpdateInput!' },
    });
  }

  private readonly _currentUserSlot = 'currentUser';

  public currentUser(): Observable<User> {
    return this.query({
      query: CurrentUserQuery,
      slot: this._currentUserSlot,
    }).pipe(map((e: any) => e?.data?.currentUser));
  }

  public refetchCurrentUser() {
    this.refetchQuery(this._currentUserSlot);
  }
}
